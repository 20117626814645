import { gql } from 'apollo-angular';

export const SIGN_IN_ADMIN = gql`
  query signInAdminUser($email: String!, $password: String!) {
    signInAdminUser(auth: { email: $email, password: $password }) {
      user {
        id
        email
        firstName
        lastName
        phone
        cognitoId
        walletAddress
        birthday
        maritalStatus
        gender
        address {
          streetName
          streetNumber
          postalCode
          city
          province
          apartment
        }
        country {
          alias
          name
        }
      }
      tokens {
        accessToken
        idToken
        refreshToken
      }
    }
  }
`;
