import { FormControl } from '@angular/forms';
import { Translation } from '../interfaces/translation';
import { FileUpload } from '../interfaces/file';

export class Utils {
  public static isTokenExpired = (token: string) => {
    if (!!token && token !== '') {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      const today = Math.floor(new Date().getTime() / 1000);
      return today >= expiry;
    } else {
      return true;
    }
  };

  public static async pickupImageFile(file: any, extensions: string[]): Promise<FileUpload | null> {
    const ext = file.name.split('.').pop().toLowerCase();
    if (!extensions.includes(ext)) {
      // @ToDo: show an alert
      return null;
    }
    return {
      acl: 'public-read',
      contentType: extensions.includes('mp4') ? 'VIDEO' : 'IMAGE',
      name: file.name,
      data: await Utils.file2Base64(file)
    };
  }

  public static file2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = error => reject(error);
    });
  }

  // @ToDo rename this to get key as string by value
  public static enumGetValueByKey = <T>(key: any, enumObject: any): string => {
    const indexOfStatus = Object.values(enumObject).indexOf(key as unknown as T);
    return Object.keys(enumObject)[indexOfStatus];
  }

  public static getEnumValue<T>(key: keyof T, enumObj: any) {
    return enumObj[key];
  }

  public static toggleFormControls(controls: FormControl[], enable: boolean) {
    controls.forEach(control => {
      if (enable) {
        control.enable({onlySelf: true, emitEvent: false});
      } else {
        control.disable({onlySelf: true, emitEvent: false});
      }
    });
  }

  public static getValueByLanguage(descriptions: Translation[], language: string): Translation | null {
    const found = descriptions.find(t => t.translationId === language);
    return found ? found : null;
  }

}
