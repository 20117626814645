export class AppConstants {

  public static style: any = {
    fontWeight: '400',
    color: '#000000',
    fontSize: '10px',
    textAlign: 'left',
  };


  public static safeQueries = [
    'getFields',
    'signIn',
    'signInAdminUser',
    'signUp',
    'editUser',
    'getCountries',
    'getUsers',
    'verifyUserEmail'
  ];
  public static allowedVideoExtensions = ['mp4'];
  public static allowedImageExtensions = ['jpg', 'tiff', 'gif', 'png', 'jpeg'];
  public static allowedPdfImageExtensions = ['jpg', 'tiff', 'gif', 'png', 'jpeg', 'pdf', 'svg'];
  public static criaUserId = "clb18v3sd0001hcv1yc5j19o6";
  public static feeRate = 35;
  public static exchangeRate = 390;
  public static marketplaceListFields = [
    {
      title: 'Nombre y apellido',
      dataProperty: 'userFullName',
      styles: this.style
    },
    {
      title: 'ID',
      dataProperty: 'userIdentificationValue',
      styles: this.style
    },
    {
      title: 'Correo electrónico',
      dataProperty: 'userEmail',
      styles: this.style
    },
    {
      title: 'Celular',
      dataProperty: 'userPhoneNumber',
      styles: this.style
    },
    {
      title: 'Fecha',
      dataProperty: 'createDate',
      sortProperty: 'CREATEDATE',
      styles: this.style
    },
    {
      title: 'Publicación',
      dataProperty: 'publicationHorse',
      sortProperty: 'TYPE',
      styles: this.style
    },
    {
      title: 'Tokens',
      dataProperty: 'totalTokenAmount',
      sortProperty: 'TOTALTOKENAMOUNT',
      styles: this.style
    },
    {
      title: 'Valor unitario',
      dataProperty: 'tokenPrice',
      sortProperty: 'TOKENPRICE',
      styles: this.style
    },
    {
      title: 'Valor total',
      dataProperty: 'totalAmountUSD',
      sortProperty: 'TOTALAMOUNTUSD',
      styles: this.style
    },
    {
      title: 'Acciones',
      styles: {
        textAlign: 'left'
      },
      actions: [
        {
          tooltip: 'Editar',
          actionType: 'button',
          icon: 'visibility',
          showOption: (_: any) => true,
        },
      ],
    },
  ];
  public static transactionListFields = [
    {
      title: 'Nombre',
      dataProperty: 'user.firstName',
      styles: this.style
    },
    {
      title: 'Apellido',
      dataProperty: 'user.lastName',
      styles: this.style
    },
    {
      title: 'Fecha',
      dataProperty: 'createDate',
      sortProperty: 'CREATEDATE',
      styles: this.style
    },
    {
      title: 'Publicación',
      dataProperty: 'publication.ownership.horse.name',
      styles: this.style
    },
    {
      title: 'Tokens',
      dataProperty: 'totalTokenAmount',
      sortProperty: 'TOTALTOKENAMOUNT',
      styles: this.style
    },
    {
      title: 'Compra estimada (USD)',
      dataProperty: 'totalAmountUSD',
      sortProperty: 'TOTALAMOUNTUSD',
      styles: this.style
    },
    {
      title: 'Acciones',
      styles: {
        textAlign: 'left',
      },
      actions: [
        {
          tooltip: 'Editar',
          actionType: 'button',
          icon: 'edit',
          showOption: (_: any) => true,
        },
      ],
    },
  ];
  public static modal = {
    sizes: {
      tiny: '390px',
      normal: '490px',
      small: '615px',
      medium: '798px',
      big: '950px',
      auto: 'auto',
    }
  }
  public static keys: any = {
    user: 'user',
    userRemember: 'userRemember',
    token: 'idToken',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    lang: 'lang'
  };

  public static formats: any = {
    date: 'YYYY-MM-DD'
  };


  public static usersListFields = [
    {
      title: 'Nombre',
      dataProperty: 'firstName',
      sortProperty: 'FIRSTNAME',
      styles: this.style
    },
    {
      title: 'Apellido',
      dataProperty: 'lastName',
      sortProperty: 'LASTNAME',
      styles: this.style
    },
    {
      title: 'DNI',
      dataProperty: 'identificationValue',
      sortProperty: 'IDENTIFICATIONVALUE',
      styles: this.style
    },
    {
      title: 'Correo electrónico',
      dataProperty: 'email',
      sortProperty: 'EMAIL',
      styles: this.style
    },
    {
      title: 'Celular',
      dataProperty: 'phone',
      sortProperty: 'PHONE',
      styles: this.style
    },
    {
      title: 'Compra (USD)',
      dataProperty: '_totalInvestedUsd',
      sortable: false,
      styles: this.style
    },
    {
      title: 'Inv. Max. Mensual',
      dataProperty: 'level',
      sortable: false,
      styles: this.style
    },
    {
      title: 'Acciones',
      styles: {
        textAlign: 'left',
      },
      actions: [
        {
          tooltip: 'Editar',
          actionType: 'button',
          icon: 'info_outline',
          showOption: (_: any) => true,
        },
      ],
    },
  ];

  public static examplesOfDates = {
    dateFormat1: '23 Nov 12:12 PM'
  }

  public static getFileExtension(video: boolean | null) {
    return video && !!video ? this.allowedVideoExtensions : this.allowedImageExtensions;
  }

  public static fixedTaxes: number = 25;

}
