import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GraphqlService } from './graphql.service';
import to from 'await-to-js';
import { SIGN_IN_ADMIN } from '../../graphql/auth/query';
import { LocalStorageService } from './local-storage.service';
import { AppConstants } from 'src/app/app.constants';
import { User, UserToken } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private graphqlService: GraphqlService<any>,
    private localStorageService: LocalStorageService
  ) {}

  async login(email: string, password: string): Promise<any> {
    const auth = { email, password };
    const [error, response] = await to<any>(
      lastValueFrom(
        this.graphqlService.callQuery(SIGN_IN_ADMIN, auth, 'network-only')
      )
    );
    if (response) {
      const user = response.data.signInAdminUser.user;
      this.localStorageService.save(AppConstants.keys.user, response.data.signInAdminUser);
      const tokens = response.data.signInAdminUser.tokens;
      this.localStorageService.save('tokens', tokens);
      return user;
    }
    if (error) {
      console.error(error);
      throw error;
    }
  }

  logout(): void {
    this.localStorageService.clear(['user', 'tokens']);
  }

  getUser(): User {
    return this.localStorageService.load(AppConstants.keys.user).user;
  }

  getTokens(): UserToken {
    return this.localStorageService.load(AppConstants.keys.user).tokens;
  }
}
