import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from '../utils/utils';
import { AppConstants } from '../../app.constants';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private token: string | undefined;
  constructor(private spinner: NgxSpinnerService,
              private authService: AuthService) {
  }

  private handleHttpRequest = tap({
    next: (event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
    },
    error: (_: any) => {
      this.spinner.hide();
    }
  });

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinner.show();
    if (!request.url.includes('/graphql') || this.isUnsecuredOperation(request)) {
      return next.handle(request).pipe(this.handleHttpRequest);
    }
    this.token = this.authService.getTokens().accessToken;
    if (this.token && Utils.isTokenExpired(this.token)) {
      this.handleExpiredToken();
    }
    return this.handleSetHeader(next, request);
  }

  private isUnsecuredOperation(request: any): boolean {
    return AppConstants.safeQueries.includes(request?.body?.operationName);
  }

  private handleSetHeader(next: any, request: any): Observable<any> {
    request = request.clone({
      setHeaders: { Authorization: this.token }
    });
    return next.handle(request).pipe(
      catchError((error) => {
        console.log(error);
        return throwError({ error: error.name, message: error.message });
      }), this.handleHttpRequest
    );
  }

  private handleExpiredToken() {
    this.authService.logout();
  }
}
