/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchPolicy } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { lastValueFrom, Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { ErrorCodes } from '../enums/error-codes';
import { copyWith } from '../utils/copy-with';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService<T> {

  constructor(
    private apollo: Apollo,
  ) {
  }

  watchQuery(gqlString: any, variables?: any) {
    return this.apollo.watchQuery<T, any>({
      query: gqlString,
      variables,
      errorPolicy: 'all',
    });
  }

  query(
    gqlString: any,
    variables?: any,
    fetchPolicy: FetchPolicy = 'cache-first'
  ): Observable<ApolloQueryResult<T>> {
    const key = Object.keys(variables)[0];
    const values = Object.values(variables)[0];
    const setVariables: any = {};
    setVariables[key] = values;
    return this.apollo.query({
      query: gqlString,
      variables: { ...setVariables },
      errorPolicy: 'all',
      fetchPolicy,
    });
  }

  setQuery(gqlString: any, variables?: any): Observable<ApolloQueryResult<any>> {
    return this.apollo
      .query({
        query: gqlString,
        variables,
        errorPolicy: 'all',
      })
      .pipe(
        tap(({ errors }) => {
          if (errors) {
            errors.find(({ error }: any) => {
              if (error.extensions.code === ErrorCodes.INTERNAL_SERVER_ERROR) {
                throw new Error(error.message);
              }
            });
          }
        }),
        map((response) => copyWith(response)),
        catchError(error => {
          console.error(error);
          throw error;
        }),
      );
  }

  mutation(gqlString: any, variables: any): Observable<any> {
    const setVariables: any = {};
    const keys = Object.keys(variables);

    for (const key of keys) {
      setVariables[key] = variables[key];
    }
    return this.apollo.mutate({
      mutation: gql`
        ${ gqlString }
      `,
      variables: { ...setVariables },
    });
  }

  promiseMutation(gqlString: any, variables: any) {
    return lastValueFrom(this.mutation(gqlString, variables));
  }

  callQuery(query: any, variables: any, fetchPolicy = 'cache-first') {
    return this.apollo
      .query({
        query,
        variables,
        // @ts-ignore
        fetchPolicy
      });
  }

  promiseQuery(query: any, variables: any, fetchPolicy = 'cache-first') {
    return lastValueFrom(this.callQuery(query, variables, fetchPolicy));
  }

}
