import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  constructor(
    private router: Router,
  ) {
  }

  save(key: string, value: string): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  load(key: string) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value || '') : null;
  }

  clear(keys: string[]) {
    for (const i of keys) {
      localStorage.removeItem(i);
    }
    this.router.navigate(['auth', 'login']).then();
  }

}
